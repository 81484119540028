<template>
  <CRow>
    <CCol col="12">
      <CModal :show.sync="showModalCRUD" :centered="true" :title="$t(current_crud.title)" size="lg">
        <template v-if="showModalCRUD">
          <ModalCRUD :options="current_crud" @modalUpdated="onModalUpdated" />
        </template>
      </CModal>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit bank account') + ` id: ${form.id}` : $t('Create bank account') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard title="Identification and Location">
              <AMultiSelectMaster name="bank_id" label="Bank" v-model="form.bank_id" :options="lists.banks" :isValid="isValid('bank_id')" :errors="getErrors('bank_id')" @addElement="onAddElement('bank')" />
              <ACheckBox label="Active" placeholder="Marca la cuenta como activa o inactiva para asentar pagos." v-model="form._status" />

              <hr />
              <AInput label="Name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />
              <AInput label="# Account" v-model="form.account_number" :isValid="isValid('account_number')" :errors="getErrors('account_number')" />
              <AInput label="Payment URL" v-model="form.external_payment_url" />
              <ACheckBox :disabled="is_principal" label="Principal account" placeholder="Activa como cuenta principal. Aparece en la factura." v-model="form.is_principal" />
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'BankAccountForm',
  mixins: [formMixin],

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0 },

      // Extra
      lists: {
        banks: [],
        bank_account_types: []
      },

      // Helpers
      alerts: [],
      validators: {},
      current_crud: {},
      is_principal: false,
      showModalCRUD: false
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/bank_accounts' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },
    onAddElement(type) {
      this.current_crud = {
        url: `admin/${type}s`, // Pluralize
        name: type,
        type: `${type}s`, // Pluralize
        field: `${type}_id`,
        title: this._humanize(type),
        modal: true,
        fiels: ['name']
      }
      this.showModalCRUD = true
    },
    onModalUpdated(items) {
      this.lists[this.current_crud.type] = items
      // Force refresh list
      const value = this.form[this.current_crud.field]
      this.form[this.current_crud.field] = ''
      this.$nextTick(() => {
        this.form[this.current_crud.field] = value
      }, 100)
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.bank_account ? data.bank_account : { id: 0 }
      this.is_principal = !!this.form.is_principal
      this.lists = data._lists || {}

      this.parseView()

      this.validators = {
        main: data._validation || {}
        //extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'BankAccount']: options })
    },

    // SUBMIT

    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      const self = this
      self.$http[self.form.id ? 'put' : 'post']('admin/bank_accounts', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/property/bank_accounts/${response.data.bank_account.id}/edit`).catch(() => {})
          self.showAlert(`Bank account ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the bank account.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
